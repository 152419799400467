

























import { Component, Vue } from "nuxt-property-decorator";

@Component<MNavbarHamburgerMenu>({})
export default class MNavbarHamburgerMenu extends Vue {
  onLanguageSwitch(lang: string) {
    if (lang) {
      this.$ga.event({
        eventCategory: "LanguageSwitch",
        eventAction: lang.toUpperCase(),
        eventLabel: `Zmieniono język na: ${lang.toUpperCase()}`
      });
    }
  }

  items = [
    {
      to: null,
      label: "navbar.menu.services", // Services
      children: [
        {
          children: [
            {
              to: { name: "software-e-commerce" },
              label: "navbar.menu.e-commerce" // E-commerce systems
            },
            {
              to: { name: "services-web-development" },
              label: "navbar.menu.web-development" // Web development
            },
            {
              to: { name: "services-mobile-development" },
              label: "navbar.menu.mobile-development" // Mobile development
            }
          ]
        },
        {
          children: [
            {
              to: { name: "services-dedicated-software" },
              label: "navbar.menu.dedicated-software" // Dedicated software
            },
            {
              to: { name: "services-ui-ux-design" },
              label: "navbar.menu.ui-ux-design" // UI/UX design
            },
            {
              to: { name: "services-design-workshops" },
              label: "navbar.menu.design-workshops" // Design workshops
            }
          ]
        }
      ]
    },
    {
      to: { name: "portfolio" },
      label: "navbar.menu.portfolio" // Portfolio
    },
    {
      to: { name: "career" },
      label: "navbar.menu.career" // Career
    },
    {
      to: { name: "blog" },
      label: "navbar.menu.blog" // Blog
    },
    {
      to: { name: "about" },
      label: "navbar.menu.about" // About
    },
    {
      to: { name: "contact" },
      label: "navbar.menu.contact" // Contact
    }
  ];

  languages = ["pl", "en", "no", "de"];
}
















import { Component, Vue } from "nuxt-property-decorator";

@Component<MStatsBadges>({})
export default class MStatsBadges extends Vue {}
